<template>
    <div class="single-chart" :class="[$mq]">
        <div class="chart-container" v-if="series1">
            <apexchart :ref="'chart'" width="100%" type="area" height="135px" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div class="chart-container" v-else>
            <div class="greybox"></div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'ChartSales',
    props: {
        series1: {
            type: Object,
            default: false
        },
        series2: {
            type: Object,
            default: false
        }
    },
    data() {
        return {
            hasAlerts: false,
            sendValueCharts: false
        }
    },
    computed: {
        series() {
            if (this.series1) {
                return [
                    {
                        name: 'PMIX',
                        data: Object.values(this.filteredData)
                    },
                    {
                        name: 'Forecast',
                        data: Object.values(this.filteredDataForecast)
                    }
                ]
            }
            return false
        },
        filteredData() {
            const entries = Object.entries(this.series1)
            const firstNonZeroIndex = entries.findIndex(([time, value]) => value !== 0)
            const lastNonZeroIndex = entries.length - 1 - [...entries].reverse().findIndex(([time, value]) => value !== 0)

            const filteredEntries = entries.slice(16, lastNonZeroIndex + 1)
            return Object.fromEntries(filteredEntries)
        },
        filteredDataForecast() {
            // get filteredData ang fet values from that positions

            const entries = Object.entries(this.series2)
            const firstNonZeroIndex = entries.findIndex(([time, value]) => value !== 0)
            const lastNonZeroIndex = entries.length - 1 - [...entries].reverse().findIndex(([time, value]) => value !== 0)

            const filteredEntries = entries.slice(16, lastNonZeroIndex + 1)
            return Object.fromEntries(filteredEntries)
        },
        chartOptions() {
            return {
                chart: {
                    animations: {
                        enabled: false
                    },
                    id: false,
                    toolbar: {
                        show: false
                    },
                    background: '#fff',
                    zoom: {
                        enabled: false
                    },
                    offsetY: -20
                },
                colors: ['#374673', '#e08c43'],
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (value) {
                            // without decimals
                            return value.toFixed(0) + ' €'
                        }
                    }
                },

                xaxis: {
                    type: 'datetime',
                    categories: Object.keys(this.filteredData).map(function (hour) {
                        if (moment(hour).isValid()) return hour
                        else return moment().format('YYYY-MM-DD') + ' ' + hour
                    }),
                    hideOverlappingLabels: true,
                    style: {
                        fontSize: '20px'
                    },
                    format: 'HH:mm',
                    formatter: function (value) {
                        return moment(value).format('HH:mm')
                    },
                    labels: {
                        datetimeUTC: false
                    }
                },
                yaxis: {
                    formatter: function (value) {
                        return Math.round(value) + ' €'
                    },
                    min: 0,
                    hideOverlappingLabels: true,
                    tickAmount: 4,
                    forceNiceScale: true,
                    decimalsInFloat: false
                },
                //nodata
                noData: {
                    text: i18n.t('sensor.no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#000',
                        fontSize: '14px',
                        fontFamily: 'Avenir-Regular'
                    }
                },
                legend: {
                    show: true
                },
                stroke: {
                    curve: 'smooth',
                    width: 3
                }
            }
        }
    },
    methods: {}
}
</script>

<style lang="scss">
.single-sensor-chart {
    width: calc(100%);
    height: auto;
    box-shadow: 0px 0px 5px #e3e7ed;

    // media and max-width
    @media (max-width: 600px) {
        width: calc(100%);
    }

    @media (min-width: 601px) {
        width: calc(50% - 6px);
    }

    @media (min-width: 1200px) {
        width: calc(33% - 6px);
    }

    @media (min-width: 1600px) {
        width: calc(25% - 9px);
    }

    .top-chart {
        @include display-flex();
        @include align-items(center);
        background-color: $inactive-t80;
        border-radius: 2px 2px 0px 0px;
        padding: 7px;
        height: 50px;

        .left-container {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            background-color: #fff;
            height: 27px;
            padding: 6px;

            .alerts {
                @include display-flex();
                @include align-items(center);
                .number {
                    font-family: $text-bold;
                    @include font-size(sm);
                    padding-right: 4px;
                    position: relative;
                    top: 2px;
                }
                .icon-img {
                    // @include background($image: img('alert_sensor.svg'), $position: center, center);
                    background-image: url('../../../../public/img/alert_sensor.svg');
                    width: 17px;
                    height: 17px;
                    background-size: 100%;
                }
            }
            .check {
                background-image: url('../../../../public/img/checked_ok.svg');
                width: 30px;
                height: 30px;
                background-size: 100%;
            }
            &.no-alerts {
                background-color: unset;
            }
        }
        .right-container {
            @include display-flex();
            @include flex-direction(column);
            @include justify-content(center);
            padding-left: 12px;
            .name-sensor {
                @include text-ellipsis();
                font-family: $text-bold;
                width: 100%;
                @include font-size(m);
            }
            .id-sensor {
                @include display-flex();
                font-family: $text-xlight;
                @include font-size(s);
                color: $neutro-s80;
            }
        }
        &.alert {
            cursor: pointer;
            $alert: #ffc700;
            background-color: $alert;

            &:hover {
                @include bgHover($alert);
            }
        }
        &:hover {
            cursor: pointer;
            @include bgHover($inactive-t80);
        }
    }
    .chart-container {
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 5px #e3e7ed;
        @include border-radius(0px 0px 2px 2px);
        height: calc(100% - 60px);

        &.nodata {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
        }
        .greybox {
            height: 250px;
            @include background($image: img('loader_dark.svg'), $position: center center, $size: 50px, $color: #fff);
        }
    }
    &.portrait {
        .right-container {
            .name-sensor {
                @include font-size(s);
            }
            .id-sensor {
                @include font-size(xs);
            }
        }
    }
}
</style>
